export const PERSISTENCE_PREFIX = "UNU_HEALTH_";
export const namespaces = ["translation"];
export const LANGUAGE_PARAM_KEY = "lng";
export const STORAGE_LANGUAGE_PARAM_KEY =
  PERSISTENCE_PREFIX + LANGUAGE_PARAM_KEY;
export const supportedLanguages = [
  {
    code: "en",
    label: "English",
  },
];

export const supportedLanguageCodes = supportedLanguages.map((language) => {
  return language.code;
});

export function getLanguageLabel(languageCode) {
  const foundLanguage = supportedLanguages.find(
    (language) => language.code === languageCode
  );

  return foundLanguage ? foundLanguage.label : "";
}

export const options = {
  debug: false,
  returnNull: false,
  detection: {
    order: ["querystring", "localStorage", "navigator"],
    lookupQuerystring: LANGUAGE_PARAM_KEY,
    lookupLocalStorage: STORAGE_LANGUAGE_PARAM_KEY,
  },
  fallbackLng: supportedLanguageCodes[0],
  defaultNS: namespaces[0],
  namespaces,
  supportedLanguageCodes,
};
