import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { options, supportedLanguages } from "./config";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(options)
  .then(() => {
    i18n.changeLanguage().catch(() => {});
  })
  .catch(() => {});

export default i18n;
export { supportedLanguages };
