import { setup, assign } from "xstate";

interface Context {
  totalSteps: number;
  currentStepValue: number;
  transitionDuration: number;
}

export const eventNames = {
  PROCEED_TO_NEXT_STEP: "PROCEED_TO_NEXT_STEP",
};

const initialContextValues: Context = {
  totalSteps: 5,
  currentStepValue: 1,
  transitionDuration: 3,
};

export const CVPFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as { type: "PROCEED_TO_NEXT_STEP" },
  },
  schemas: {
    events: {
      PROCEED_TO_NEXT_STEP: {
        type: "object",
        properties: {},
      },
    },
  },
}).createMachine({
  context: initialContextValues,
  id: "CVPFlow",
  initial: "CVPHealthCheck",
  states: {
    CVPHealthCheck: {
      on: {
        PROCEED_TO_NEXT_STEP: {
          target: "CVPHealthScore",
        },
      },
      entry: assign(initialContextValues),
    },
    CVPHealthScore: {
      on: {
        PROCEED_TO_NEXT_STEP: {
          target: "CVPMedicalAssistance",
        },
      },
      entry: assign({ currentStepValue: 2 }),
    },
    CVPMedicalAssistance: {
      on: {
        PROCEED_TO_NEXT_STEP: {
          target: "CVPHealthPlan",
        },
      },
      entry: assign({ currentStepValue: 3 }),
    },
    CVPHealthPlan: {
      on: {
        PROCEED_TO_NEXT_STEP: {
          target: "CVPLastStep",
        },
      },
      entry: assign({ currentStepValue: 4 }),
    },
    CVPLastStep: {
      entry: assign({ currentStepValue: 5 }),
    },
  },
});
