import IconLoader from "@/features/Common/components/IconLoader";
import { convertPxToRem } from "@/utils";
import { Stack, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import "./animations.css";

interface HealthPlansAnimatedProps {
  onClick: () => void;
  shouldAnimate?: boolean;
}

export default function HealthPlansAnimated({
  onClick,
  shouldAnimate = true,
}: HealthPlansAnimatedProps) {
  const { t } = useTranslation();

  function onNextButtonClick() {
    onClick();
  }

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%" }}>
      <Stack
        alignItems="center"
        bgcolor="accent2.main"
        sx={{
          clipPath: "ellipse(200% 57% at 15% 42%)",
          height: "60%",
          maxHeight: "400px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <IconLoader
          icon="Pattern4"
          color="info"
          sx={{
            fontSize: convertPxToRem(333),
            position: "relative",
            bottom: convertPxToRem(-100),
            left: (theme) => theme.spacing(1),
          }}
        />
        <Stack
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(14),
            alignSelf: "center",
            zIndex: 2,
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <IconLoader
            icon="SunIcon"
            color="secondary"
            sx={{
              fontSize: convertPxToRem(260),
              ...(shouldAnimate === true && {
                animation:
                  "slideUpAndFadeIn 0.5s, rotating 50s linear infinite",
              }),
            }}
          />
          <Box
            sx={{
              position: "absolute",
              alignSelf: "center",
              width: "50%",
              textAlign: "center",
              ...(shouldAnimate === true && {
                animation: "slideUpAndFadeIn 0.5s",
              }),
            }}
          >
            <Typography fontWeight={600} variant="h3">
              {t("CVPFlow.screens.HealthPlans.iconText")}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Stack p={2} spacing={4}>
        <Typography variant="h1" textAlign="center">
          {t("CVPFlow.screens.HealthPlans.title")}
        </Typography>
        <ButtonWithAnalytics
          page="Health plans CVP"
          text={t("common.nextButton")}
          intent="navigational"
          onClick={onNextButtonClick}
        >
          {t("common.nextButton")}
        </ButtonWithAnalytics>
      </Stack>
    </Stack>
  );
}
